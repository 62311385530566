<template>
  <Header></Header>
  <main>
    <div class="container">
      <h1>Liebe:r Besucher:in,</h1>
      <p>vielen Dank für Ihren Besuch.</p>
      <p>
        Leider hatte das Jahr 2022 mehr Tiefen als Höhen für uns bereitgehalten.
        Wir sind gefallen, haben uns aufgerappelt und sind nochmal neu
        angelaufen. Wir haben uns und unser Geschäftsmodell hinterfragt, haben
        uns stets der Wahrheit und Marktgegebenheiten gestellt. Wir sind
        nochmals in tiefere Gespräche mit unseren Nutzern und haben im September
        einen umfangreichen Pivot durchlaufen. Leider ohne den notwendigen
        Erfolg.
      </p>
      <p>
        Schweren Herzens und nach einer tiefgreifenden Analyse haben wir uns nun
        dazu entschieden, den Betrieb der Plattform und allen damit verbunden
        Services endgültig einzustellen. Im Namen des gesamten Teams von The
        Practory möchten wir uns recht herzlich bei all unseren Partnern,
        Unterstützern und Mitgliedern bedanken.
      </p>
      <p>
        Wir wünschen frohe Weihnachten, viel Gesundheit und einen guten Start
        ins Jahr 2023!
      </p>
      <p><b> Ihr Team von The Practory </b></p>
    </div>
  </main>
  <Footer></Footer>
</template>

<script>
// @ is an alias to /src
import Header from "@/components/HeaderComponent";
import Footer from "@/components/FooterComponent";

export default {
  name: "HomeView",
  components: {
    Header,
    Footer,
  },
};
</script>
