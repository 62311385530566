<template>
  <header>
    <div class="container">
      <router-link class="header__logo" to="/">
        <img
          class="header__logo"
          alt="The Practory Logo"
          src="../assets/logo.png"
        />
      </router-link>
    </div>
  </header>
</template>

<script>
export default {
  name: "HeaderComponent",
  components: {},
};
</script>

<style>
header {
  background-color: #ffffff;
}

.header__logo {
  width: 120px;
}

@media screen and (min-width: 480px) {
  .header__logo {
    width: 200px;
  }
}
</style>
