<template>
  <footer>
    <div class="container container--footer">
      <ul class="footer__navigation">
        <li><router-link to="/impressum">Impressum</router-link></li>
        <li><router-link to="/datenschutz">Datenschutz</router-link></li>
      </ul>
      <div class="footer__copyright">© 2022 The Practory GmbH</div>
      <img
        class="footer__logo"
        alt="The Practory Logo"
        src="../assets/logo-white.png"
      />
    </div>
  </footer>
</template>

<script>
export default {
  name: "FooterComponent",
};
</script>

<style>
footer {
  color: #ffffff;
  position: absolute;
  bottom: 0;
  width: 100vw;
  background-color: #5f77f3;
  text-align: center;
}

.footer__logo {
  width: 120px;
  margin: 0 auto;
}

.footer__copyright {
  text-align: center;
  line-height: 2.5rem;
}
ul.footer__navigation {
  margin: 0;
  padding: 0;
  list-style: none;
}

.footer__navigation a {
  color: #ffffff;
}

.container--footer {
  display: grid;
  gap: 1rem;
  justify-content: center;
}

@media screen and (min-width: 560px) {
  .container--footer {
    grid-template-columns: 120px auto 120px;
    justify-content: space-between;
  }
}
</style>
